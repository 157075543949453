import React, { useEffect, useState } from 'react';
import './style.css';
import logo from './logo.png';

const App = () => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    // Target date for the countdown (July 9, 2023)
    const targetDate = new Date('2023-07-09').getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const timeDifference = targetDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        // Countdown has reached zero or passed the target date
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="container">
      <img src={logo} alt="Company Logo" className="logo" />
      <h1 className="title">
        TRAVEL <span className="details">DETAILS</span>
      </h1>
      <h3 className="hold-message">
        Brace Yourself! We're Almost There! Get Ready for the Countdown
      </h3>
      <div className="countdown">
        <div className="countdown-item">
          <p className="countdown-value">{countdown.days}</p>
          <p className="countdown-label">Days</p>
        </div>
        <div className="countdown-item">
          <p className="countdown-value">{countdown.hours}</p>
          <p className="countdown-label">Hours</p>
        </div>
        <div className="countdown-item">
          <p className="countdown-value">{countdown.minutes}</p>
          <p className="countdown-label">Minutes</p>
        </div>
        <div className="countdown-item">
          <p className="countdown-value">{countdown.seconds}</p>
          <p className="countdown-label">Seconds</p>
        </div>
      </div>
      <p className="current-time">
      Contact: <a href="mailto:saurav.shriwastav@traveldetails.org">saurav.shriwastav@traveldetails.org</a>
      <br />
      <br />
        Embark on an extraordinary journey with our groundbreaking platform, your ultimate destination for comprehensive travel information!
       
     
        <br />
        &copy; 2023 Travel Details. All rights reserved.
      </p>
    </div>
  );
};

export default App;
